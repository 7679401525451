import axios from 'axios'
import store from '../store'

export default {
  methods: {
    async getPricesPerHour(locationId, meetingtypeId = 0) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/price`,
        {
          params: {
            locationId: locationId,
            meetingtypeId: meetingtypeId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getPriceVariables(
      locationId,
      spaceId,
      settingId,
      seats,
      startDate,
      startMinutes,
      endDate,
      endMinutes
    ) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/price/variables`,
        {
          params: {
            locationId: locationId,
            spaceId: spaceId,
            settingId: settingId,
            seats: seats,
            startDate: startDate,
            startMinutes: startMinutes,
            endDate: endDate,
            endMinutes: endMinutes
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    }
  }
}
