<template>
  <div>
    <transition
      name="zoom-fade"
      mode="out-in"
    >
      <div class="has-text-right">
        <table class="table is-fullwidth is-striped is-narrow">
          <thead>
            <tr>
              <th width="100">{{$t('Components.Carts.CartWorkspaces.Table_Seats')}}</th>
              <th>{{$t('Components.Carts.CartWorkspaces.Table_Name')}}</th>
              <th width="100">{{$t('Components.Carts.CartWorkspaces.Table_Time')}}</th>
              <th
                width="75"
                class="has-text-right"
              >{{$t('Components.Carts.CartWorkspaces.Table_Price')}}</th>
              <th
                width="50"
                class="has-text-right"
              >{{$t('Components.Carts.CartWorkspaces.Table_Tax')}}</th>
              <th
                width="100"
                class="has-text-right"
              >{{$t('Components.Carts.CartWorkspaces.Table_Total')}}</th>
              <th width="25">&nbsp;</th>
              <th width="25">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(workspace, index) in cart.Spaces"
              :key="index"
            >
              <td>{{ workspace.Seats }}x</td>
              <td>
                <a @click="showWorkspaceSummary(workspace.SpaceId)">{{ workspace.SpaceName }}</a>
              </td>
              <td>{{ workspace.StartMinutes | minutesToTime }} - {{ workspace.EndMinutes | minutesToTime }}</td>
              <td class="has-text-right">
                <span>{{ workspace.PricePerSeat | toCurrency('nl-NL', cart.CurrencyIso)  }}</span>
                <span>
                  <a
                    @click="getPriceVariables(workspace)"
                    class="has-text-link"
                  >
                    <i class="fas fa-info-circle"></i>
                  </a>
                </span>
              </td>
              <td class="has-text-right">{{ workspace.TaxPercentage }}%</td>
              <td class="has-text-right">
                <span>{{ workspace.PriceTotal | toCurrency('nl-NL', cart.CurrencyIso) }}</span>
              </td>
              <td
                width="25"
                v-if="!isLocked"
              >
                <a
                  @click="setEditSpace(workspace, index)"
                  class="button is-small"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </span>
                </a>
              </td>
              <td
                width="25"
                v-if="!isLocked"
              >
                <a
                  @click="setDeleteSpace(workspace, index)"
                  class="button is-small"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'trash-alt']" />
                  </span>
                </a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">{{$t('Components.Carts.CartWorkspaces.Table_Subtotal')}}</td>
              <td class="has-text-right">
                <span>{{ total | toCurrency('nl-NL', cart.CurrencyIso)  }}</span>
              </td>
              <td colspan="2">&nbsp;</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </transition>
  </div>
</template>

<script>
import { EventBus } from '@/eventbus/event-bus'
import priceProvider from '@/providers/price'



export default {
  props: {
    isLocked: {
      default: false,
      type: Boolean
    },

    cart: null
  },

  data() {
    return {

    }
  },

  computed: {
    total() {
      let total = 0.0

      if (this.cart.Spaces.length > 0) {
        for (let i = 0; i <= this.cart.Spaces.length; i++) {
          let workspace = this.cart.Spaces[i]
          if (workspace && workspace.PriceTotal > 0) {
            total = total + workspace.PriceTotal
          }
        }
      }

      return total
    }
  },

  created() {

  },

  methods: {
    minutesToTime(minutes) {
      let hour = Number(Math.floor(minutes / 60))
      let minute = Number(minutes - hour * 60)

      return (
        (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute
      )
    },

    getPriceVariables(workspace) {
      let self = this
      priceProvider.methods.getPriceVariables(self.cart.LocationId, workspace.SpaceId, workspace.SettingId, workspace.Seats, workspace.StartDate, workspace.StartMinutes, workspace.EndDate, workspace.EndMinutes).then(response => {
        if (response.status === 200) {

        }
      })
    },

    showWorkspaceSummary(spaceId) {
      this.$emit('showSpaceSummary', spaceId)
    },

    setEditSpace(workspace, index) {
      workspace.Index = index
      this.$emit('showEditSpace', JSON.parse(JSON.stringify(workspace)))
    },

    setDeleteSpace(workspace, index) {
      if (workspace.Id > 0) {
        EventBus.$emit('spaceDeleted', workspace)
      } else {
        this.cart.Spaces.splice(index, 1)
      }
    }
  }
}
</script>